<template>
    <v-app class="bg">
        <v-app-bar flat color="transparent">
            <v-app-bar-title>
                <v-avatar tile width="auto">
                    <img src="@/assets/images/logo_kedasbeauty.png" alt="logo_kedasbeauty" />
                </v-avatar>
                <v-btn class="ml-2" color="white">
                    <span class="text-lowercase purple--text">kedasbeautymember.com</span>
                </v-btn>
            </v-app-bar-title>
        </v-app-bar>
        <v-row no-gutters class="fill-height align-center">
            <Form/>
        </v-row>
        <Footer/>
    </v-app>
</template>

<style scoped>
.bg {
  background: rgb(211, 195, 255);
  background: linear-gradient(
    112deg,
    rgba(211, 195, 255, 1) 0%,
    rgba(169, 247, 250, 1) 57%,
    rgba(255, 190, 223, 1) 100%
  );
}
</style>

<script>
export default {
    data(){
        return{}
    },
    components: {
        Footer: () => import("@/components/Footer.vue"),
        Form: () => import("@/components/home/Form.vue"),
    }
}
</script>